import { speed } from 'Lib/speed/speed';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import fastdom from 'fastdom';
import 'Vendor/owlcarousel/owl.carousel.min';

const $body = $('body');
let inited = false;
const cSliderOn = 'owl-carousel';
const sImg = '.main-slider2__image_loader, .main-slider2__full-pic, .main-slider2__item-bg';

function initLoaderImages($parent) {
    onScreen($parent.find(sImg), loadSrcOrBg, 0, 0, 1.6);
}

function init() {
    const $slider = $('.main-slider2__slider');

    $slider.each(function (evt) {
        const $this = $(this);
        let cArrows = 'main-slider2__arrows';
        let cDots = 'main-slider2__dots';
        const e = parseInt(evt);

        cArrows += e;
        cDots += e;
        $this.closest('.main-slider2').find('.main-slider2__arrows').addClass(cArrows);
        $this.closest('.main-slider2').find('.main-slider2__dots').addClass(cDots);

        $this.trigger('destroy.owl.carousel')
            .removeClass(cSliderOn)
            .addClass(cSliderOn)
            .owlCarousel({
                items: 1,
                loop: true,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                nav: false,
                margin: 0,
                smartSpeed: speed,
                dotsContainer: `.${cDots}.dots`,
                dotClass: 'dots__item',
                navContainer: `.${cArrows}`,
                navClass: ['main-slider2__prev arrow arrow_prev arrow_shadow', 'main-slider2__next arrow arrow_next arrow_shadow'],
                navText: ['', ''],
                responsive: {
                    0: { nav: false },
                    770: { nav: true },
                },
                onInitialized(event) {
                    initLoaderImages($(event.target));
                },
            });

        $('.main-slider2__prev, .main-slider2__next').on('mouseenter', () => {
            $this.trigger('stop.owl.autoplay');
        }).on('mouseleave', () => {
            $this.trigger('play.owl.autoplay');
        });
    });

    $body.on('eksmoImgLoadSrcOrBg', sImg, function () {
        const $img = $(this);

        fastdom.mutate(() => {
            $img.addClass('main-slider2__image_load');
        });
    });

    inited = true;
}

init();
$body.on('eksmoResizeWidth', init);